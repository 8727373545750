// @flow
import * as React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import type { FormikActions } from 'formik';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import jwtDecode from 'jwt-decode';
import { Link } from 'react-router-dom';
import { withCookies, Cookies } from 'react-cookie';
import {
  LOCALE,
  ACCESS_TOKEN,
  COOKIE_OPTIONS,
  BACK_OFFICE_URL
} from 'Utils/constants';

import { FormattedMessage, IntlProvider } from 'react-intl';
import { DEFAULT_LOCALE, messages as messagesI18N } from 'Translations/i18n';
import messages from 'Translations/messages';

import type { ContextRouter } from 'react-router-dom';
import type { Account } from 'Utils/types/Account.type';

import { connect } from 'react-redux';
import { removeMasterToken, setAccessTokenAction } from 'Store/auth';
import { getPasswordLoginStatus } from 'Store/company/passwordLoginStatus';

import Storage from 'Services/Storage';
import { AuthApi } from 'Services/Api';
import isEmail from 'Utils/isEmail';

import AuthBox from 'Components/AuthBox';
import Input from 'Components/Form/Input';
import Button from 'Components/Button';
import Alert from 'Components/Alert';
import AccountBox from 'Components/AccountBox';
import Checkbox from 'Components/Form/Checkbox';

const LocalStorage = new Storage();

const WrapAccountBox = styled.div`
  display: flex;
  justify-content: center;
`;

type Props = {
  ...ContextRouter,
  masterToken: string,
  setAccessToken: (accessToken: string) => void,
  passwordLoginStatus: any,
  getPasswordLoginStatus: Function,
  removeMasterToken: Function,
  cookies: typeof Cookies
};
type State = {
  error: string,
  initialAccount: null | Account
};

const ValidateShape = Yup.object().shape({
  username: Yup.string()
    .trim()
    .required('Username is required'),
  password: Yup.string().required('Password is required')
});

class UserLogin extends React.Component<Props, State> {
  state = {
    error: '',
    initialAccount: null
  };

  componentDidMount() {
    const { masterToken } = this.props;
    this.props.getPasswordLoginStatus(masterToken);

    const initialAccount = this.props.location.state;
    this.setState({ initialAccount });
  }

  // adminRedirect = () => {
  //   const search = this.props.location ? this.props.location.search : '';
  //   return search.indexOf('?ref=admin') > -1;
  // };

  onSubmit = async (
    formValue: { username: string, password: string, remember: boolean },
    { setSubmitting }: FormikActions
  ) => {
    try {
      const { masterToken, passwordLoginStatus } = this.props;
      const { passwordLogin } = passwordLoginStatus.data;
      const { username, password, remember } = formValue;

      let response;
      if (passwordLogin) {
        response = await AuthApi.passwordLogin({ masterToken, password });
      } else {
        response = await AuthApi.userLogin({
          username,
          password,
          masterToken
        });
      }

      const { status, message, data } = response.data;

      if (status.isSuccess()) {
        const { accessToken, lang } = data;

        const { cookies } = this.props;
        cookies.set(ACCESS_TOKEN, accessToken, COOKIE_OPTIONS);
        cookies.set(LOCALE, lang, COOKIE_OPTIONS);

        const isMasterAccount = isEmail(username);
        if (!isMasterAccount) {
          const userData: Account = jwtDecode(accessToken);
          if (remember) {
            userData.accessToken = accessToken;
          }
          LocalStorage.setAccountToLoggedList(userData);
        }

        // eslint-disable-next-line
        location.replace(BACK_OFFICE_URL);
      } else {
        this.setState({ error: message });
        setSubmitting(false);
      }
    } catch (e) {
      toast.error(e.message);
      setSubmitting(false);
    }
  };

  render() {
    const { error, initialAccount } = this.state;
    const { passwordLoginStatus } = this.props;
    const { passwordLogin } = passwordLoginStatus.data;
    const locale =
      (passwordLoginStatus &&
        passwordLoginStatus.data &&
        passwordLoginStatus.data.lang) ||
      DEFAULT_LOCALE;

    let initUsername = '';
    if (passwordLogin) {
      initUsername = 'empty';
    } else if (initialAccount) {
      initUsername = initialAccount.username;
    }

    const initialValues = {
      username: initUsername,
      password: '',
      remember: false
    };

    return (
      <IntlProvider locale={locale} messages={messagesI18N[locale]}>
        <div>
          <AuthBox>
            {passwordLoginStatus.isFetching ? (
              <i className="fa fa-spinner fa-spin" />
            ) : (
              <div>
                <h2>
                  <FormattedMessage {...messages.userLogin.header} />
                </h2>

                {initialAccount && (
                  <WrapAccountBox>
                    <Link to="/">
                      <AccountBox small account={initialAccount} />
                    </Link>
                  </WrapAccountBox>
                )}

                <p>
                  <FormattedMessage {...messages.userLogin.pleaseLogin} />
                </p>

                {error && <Alert type="danger">{error}</Alert>}

                <Formik
                  initialValues={initialValues}
                  validationSchema={ValidateShape}
                  onSubmit={this.onSubmit}
                  enableReinitialize
                >
                  {({
                    errors,
                    touched,
                    values,
                    handleChange,
                    handleBlur,
                    isValid,
                    submitCount,
                    isSubmitting
                  }) => (
                    <Form>
                      <div>
                        {!passwordLogin &&
                          !initialAccount && (
                            <FormattedMessage {...messages.user.userName}>
                              {label => (
                                <Input
                                  type="text"
                                  name="username"
                                  placeholder={label}
                                  value={values.username}
                                  error={errors.username}
                                  touched={touched.username}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                />
                              )}
                            </FormattedMessage>
                          )}
                        <FormattedMessage {...messages.user.password}>
                          {label => (
                            <Input
                              type="password"
                              name="password"
                              placeholder={label}
                              value={values.password}
                              error={errors.password}
                              touched={touched.password}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          )}
                        </FormattedMessage>
                      </div>

                      <FormattedMessage {...messages.userLogin.rememberMe}>
                        {label => (
                          <Checkbox
                            label={label}
                            name="remember"
                            value={values.remember}
                            handleChange={handleChange}
                          />
                        )}
                      </FormattedMessage>

                      <FormattedMessage {...messages.common.login}>
                        {label => (
                          <Button
                            submit
                            fullWidth
                            label={label}
                            type="primary"
                            loading={isSubmitting}
                            disabled={(submitCount && !isValid) || isSubmitting}
                          />
                        )}
                      </FormattedMessage>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
          </AuthBox>
          {!passwordLoginStatus.isFetching ? (
            <div className="text-center">
              <button
                type="button"
                onClick={() => this.props.removeMasterToken()}
                style={{ background: '0', color: '#337ab7', border: 0 }}
              >
                <FormattedMessage {...messages.userLogin.logoutCompany} />
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  masterToken: state.auth.masterToken,
  passwordLoginStatus: state.company.passwordLoginStatus
});

const mapDispatchTopProps = {
  removeMasterToken,
  getPasswordLoginStatus,
  setAccessToken: setAccessTokenAction
};

const UserLoginWithCookie = withCookies(UserLogin);

export default connect(
  mapStateToProps,
  mapDispatchTopProps
)(UserLoginWithCookie);
