import Storage from 'Services/Storage';
import { MASTER_TOKEN, ACCOUNTS_LOGGED } from 'Utils/constants';

const LocalStorage = new Storage();

export const SET_MASTER_TOKEN = 'set_master_token';
export const REMOVE_MASTER_TOKEN = 'remove_master_token';
export const SET_ACCESS_TOKEN = 'set_access_token';
export const REMOVE_ACCESS_TOKEN = 'remove_access_token';

export const setMasterTokenAction = masterToken => ({
  type: SET_MASTER_TOKEN,
  masterToken
});

export const removeMasterToken = () => {
  LocalStorage.storage.removeItem(MASTER_TOKEN);
  LocalStorage.storage.removeItem(ACCOUNTS_LOGGED);
  return {
    type: REMOVE_MASTER_TOKEN
  };
};

export const setAccessTokenAction = accessToken => ({
  type: SET_ACCESS_TOKEN,
  accessToken
});

export const removeAccessToken = () => ({
  type: REMOVE_ACCESS_TOKEN
});

const initAuthState = {
  masterToken: '',
  accessToken: ''
};

const authReducers = (state = initAuthState, action) => {
  switch (action.type) {
    case SET_MASTER_TOKEN:
      return {
        ...state,
        masterToken: action.masterToken
      };
    case REMOVE_MASTER_TOKEN:
      return {
        ...state,
        masterToken: ''
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.accessToken
      };
    case REMOVE_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: ''
      };
    default:
      return state;
  }
};

export default authReducers;
