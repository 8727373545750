// @flow
import type { Account } from 'Utils/types/Account.type';
import {
  DEVICE_TOKEN,
  MASTER_TOKEN,
  ACCESS_TOKEN,
  ACCOUNTS_LOGGED,
  LOCALE
} from 'Utils/constants';

class Storage {
  /* eslint-disable lines-between-class-members */
  storage = {};
  setItem: (key: string, value: string) => {} | any;
  getItem: (key: string) => {} | any;
  /* eslint-enable */

  constructor(storage: 'local' | 'session' = 'local') {
    this.storage = storage === 'local' ? localStorage : sessionStorage;
    this.setItem = this.storage.setItem;
    this.getItem = this.storage.getItem;
  }

  setDeviceToken = (deviceToken: string) =>
    this.storage.setItem(DEVICE_TOKEN, deviceToken);

  getDeviceToken = () => this.storage.getItem(DEVICE_TOKEN);

  setMasterToken = (masterToken: string) =>
    this.storage.setItem(MASTER_TOKEN, masterToken);

  getMasterToken = () => this.storage.getItem(MASTER_TOKEN);

  setAccessToken = (accessToken: string) =>
    this.storage.setItem(ACCESS_TOKEN, accessToken);

  getAccessToken = () => this.storage.getItem(ACCESS_TOKEN);

  setLocale = (locale: string) => this.storage.setItem(LOCALE, locale);

  getLocale = () => this.storage.getItem(LOCALE);

  setAccountToLoggedList = ({ userId, ...newAccount }: Account) => {
    const accounts = this.getAccountsLogged();

    const newList = accounts.filter(
      account => account.username !== newAccount.username
    );
    newList.push(newAccount);

    this.storage.setItem(ACCOUNTS_LOGGED, JSON.stringify(newList));
  };

  getAccountsLogged = () => {
    const accounts = this.storage.getItem(ACCOUNTS_LOGGED);

    if (!accounts) {
      return [];
    }

    return JSON.parse(accounts);
  };
}

export default Storage;
