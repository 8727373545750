// @flow
import * as React from 'react';
import classnames from 'classnames';

type Props = {
  label: string,
  type: 'default' | 'primary' | 'warning' | 'danger',
  loading?: boolean,
  disabled?: boolean,
  fullWidth?: boolean,
  submit?: boolean
};

const Button = (props: Props) => {
  const { type, label, loading, disabled, fullWidth, submit, ...other } = props;

  const classname = classnames('btn m-b', `btn-${type}`, {
    'full-width': fullWidth
  });

  return (
    <button
      type={submit ? 'submit' : 'button'}
      className={classname}
      disabled={disabled}
      {...other}
    >
      {loading ? <i className="fa fa-spin fa-spinner" /> : label}
    </button>
  );
};

Button.defaultProps = {
  loading: false,
  disabled: false,
  fullWidth: false,
  submit: false
};

export default Button;
