// @flow
export const masterLoginScope = 'containers.MasterLogin';
export const verificationScope = 'containers.Verification';
export const companiesScope = 'containers.Companies';
export const userLoginScope = 'containers.UserLogin';
export const posScope = 'containers.POS';

export default {
  common: {
    status: { id: 'common.status' },
    action: { id: 'common.action' },
    actions: { id: 'common.actions' },
    active: { id: 'common.active' },
    back: { id: 'common.back' },
    inactive: { id: 'common.inactive' },
    disabled: { id: 'common.disabled' },
    disable: { id: 'common.disable' },
    create: { id: 'common.create' },
    delete: { id: 'common.delete' },
    update: { id: 'common.update' },
    cancel: { id: 'common.cancel' },
    remove: { id: 'common.remove' },
    reset: { id: 'common.reset' },
    errors: { id: 'common.errors' },
    export: { id: 'common.export' },
    print: { id: 'common.print' },
    uploading: { id: 'common.uploading' },
    finish: { id: 'common.finish' },
    save: { id: 'common.save' },
    search: { id: 'common.search' },
    next: { id: 'common.next' },
    previous: { id: 'common.previous' },
    page: { id: 'common.page' },
    of: { id: 'common.of' },
    continue: { id: 'common.continue' },
    dashboard: { id: 'common.dashboard' },
    logout: { id: 'common.logout' },
    login: { id: 'common.login' },
    id: { id: 'common.id' },
    printer: { id: 'common.printer' },
    fontColor: { id: 'common.fontColor' },
    backColor: { id: 'common.backColor' },
    backOffice: { id: 'common.backOffice' },
    image: { id: 'common.image' },
    price: { id: 'common.price' },
    noResults: { id: 'common.noResults' },
    noRows: { id: 'common.noRows' },
    cannotDelete: { id: 'common.cannotDelete' },
    fileIsNotSupported: { id: 'common.fileIsNotSupported' },
    rowNo: { id: 'common.rowNo' },
    additionalText: { id: 'common.additionalText' },
    additionalTexts: { id: 'common.additionalTexts' },
    additionalArticles: { id: 'common.additionalArticles' },
    mainGroup: { id: 'common.mainGroup' },
    subGroup: { id: 'common.subGroup' },
    noGroup: { id: 'common.noGroup' },
    welcome: { id: 'common.welcome' },
    privacy: { id: 'common.privacy' },
    termsOfUse: { id: 'common.termsOfUse' },
    imprint: { id: 'common.imprint' },
    forgotPassword: { id: 'common.forgotPassword' },
    depositArticles: { id: 'common.depositArticles' },
    defaultPrinter: { id: 'common.defaultPrinter' },
    defaultParameter: { id: 'common.defaultParameter' },
    description: { id: 'common.description' },
    pageNotFound: { id: 'common.pageNotFound' },
    userGroup: { id: 'common.userGroup' },
    userGroups: { id: 'common.userGroups' },
    comment: { id: 'common.comment' },
    generalInformation: { id: 'common.generalInformation' },
    salutation: { id: 'common.salutation' },
    employee: { id: 'common.employee' },
    port: { id: 'common.port' },
    ssl: { id: 'common.ssl' },
    group: { id: 'common.group' },
    usersAdministration: { id: 'common.usersAdministration' },
    articlesManagement: { id: 'common.articlesManagement' },
    companySettings: { id: 'common.companySettings' },
    masterData: { id: 'common.masterData' },
    mainSubGroup: { id: 'common.mainSubGroup' },
    generalPOSSettings: { id: 'common.generalPOSSettings' },
    user: { id: 'common.user' },
    users: { id: 'common.users' },
    companyName: { id: 'common.companyName' },
    companyAddress: { id: 'common.companyAddress' },
    registration: { id: 'common.registration' },
    register: { id: 'common.register' },
    haveAccount: { id: 'common.haveAccount' },
    dontHaveAccount: { id: 'common.dontHaveAccount' }
  },
  personal: {
    name: { id: 'personal.name' },
    firstName: { id: 'personal.firstName' },
    lastName: { id: 'personal.lastName' },
    mobile: { id: 'personal.mobile' },
    mphone: { id: 'personal.mphone' },
    birthday: { id: 'personal.birthday' },
    telephone: { id: 'personal.telephone' },
    notes: { id: 'personal.notes' },
    website: { id: 'personal.website' }
  },
  address: {
    street: { id: 'address.street' },
    city: { id: 'address.city' },
    town: { id: 'address.town' },
    country: { id: 'address.country' },
    nation: { id: 'address.nation' },
    postCode: { id: 'address.postCode' },
    postalCode: { id: 'address.postalCode' }
  },
  user: {
    userName: { id: 'user.userName' },
    email: { id: 'user.email' },
    password: { id: 'user.password' },
    retypePassword: { id: 'user.retypePassword' }
  },
  confirmBox: {
    header: { id: 'confirm.header' },
    cannotUndo: { id: 'confirm.cannotUndo' },
    formIsNotSaved: { id: 'confirm.formIsNotSaved' }
  },
  masterLogin: {
    masterAccountLogin: {
      id: `${masterLoginScope}.masterAccountLogin`
    },
    toSeeItInAction: {
      id: `${masterLoginScope}.toSeeItInAction`
    },
    pleaseWaitIn: {
      id: `${masterLoginScope}.pleaseWaitIn`
    },
    forNextLogin: {
      id: `${masterLoginScope}.forNextLogin`
    },
    forgotPassword: {
      id: `${masterLoginScope}.forgotPassword`
    }
  },
  verification: {
    header: {
      id: `${verificationScope}.header`
    },
    firstTime: {
      id: `${verificationScope}.firstTime`
    },
    tips: {
      id: `${verificationScope}.tips`
    },
    code: {
      id: `${verificationScope}.code`
    },
    resendCode: {
      id: `${verificationScope}.resendCode`
    }
  },
  companies: {
    pleaseChooseCompany: {
      id: `${companiesScope}.pleaseChooseCompany`
    },
    noCompanyFound: {
      id: `${companiesScope}.noCompanyFound`
    }
  },
  userLogin: {
    header: {
      id: `${userLoginScope}.header`
    },
    pleaseLogin: {
      id: `${userLoginScope}.pleaseLogin`
    },
    rememberMe: {
      id: `${userLoginScope}.rememberMe`
    },
    logoutCompany: {
      id: `${userLoginScope}.logoutCompany`
    }
  },
  pos: {
    welcome: {
      id: `${posScope}.welcome`
    }
  }
};
