import type { Company } from 'Utils/types';

// eslint-disable-next-line import/prefer-default-export
export class CompanyConverter {
  static convertCompanies(companies: Company[]): Company[] {
    if (!companies || companies.length === 0) {
      return [];
    }

    const index = companies.findIndex(item => item.name === 'Master store');
    if (index >= 0) {
      const masterStore = companies[index];
      companies.splice(index, 1);
      const sortedCompanies = companies.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      sortedCompanies.push(masterStore);
      return sortedCompanies;
    }

    return companies.sort((a, b) => a.name.localeCompare(b.name));
  }
}
