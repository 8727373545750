// @flow
import axios from 'axios';

import type {
  RegisterCredential,
  MasterAccountCredential,
  UserCredential
} from 'Utils/types/Credential.type';
import { APP_API_DOMAIN, APP_API_VERSION } from 'Utils/constants';
import { ResponseCode } from 'Services/Api/ApiResponse';

export class Api {
  static axiosInstance(options = {}) {
    const api = axios.create({
      baseURL: APP_API_DOMAIN,
      headers: { ACCEPT: APP_API_VERSION },
      ...options
    });

    api.interceptors.response.use(
      async response => {
        const { data } = response;
        const { code } = data;
        const status = new ResponseCode(code);
        return { ...response, data: { ...data, status } };
      },
      error => Promise.reject(error)
    );

    return api;
  }
}

export class AuthApi extends Api {
  static register(data: RegisterCredential) {
    const axiosInstance = this.axiosInstance();
    return axiosInstance.post('/company/register', data);
  }

  static masterLogin(data: MasterAccountCredential) {
    const axiosInstance = this.axiosInstance();
    return axiosInstance.post('/auth/login/reseller', data);
  }

  static getCompanies(data) {
    const axiosInstance = this.axiosInstance();
    return axiosInstance.post('/auth/stores', data);
  }

  static verification(data: { code: string }) {
    const axiosInstance = this.axiosInstance();
    return axiosInstance.post('/auth/verify/device', data);
  }

  static userLogin(data: UserCredential) {
    const axiosInstance = this.axiosInstance();
    return axiosInstance.post('/auth/login/normal', data);
  }

  static resendVerificationCode(data: { deviceToken: string }) {
    const axiosInstance = this.axiosInstance();
    return axiosInstance.post('/auth/resend-verification-code', data);
  }

  static passwordLogin(data: { masterToken: string, password: string }) {
    const axiosInstance = this.axiosInstance();
    return axiosInstance.post('/auth/login/password-login', data);
  }

  static passwordLoginStatus(masterToken: string) {
    const axiosInstance = this.axiosInstance();
    return axiosInstance.post('/company/password-login-status', {
      masterToken
    });
  }

  static tokenExchange(data: { token: string }) {
    const axiosInstance = this.axiosInstance();
    return axiosInstance.post('/company/exchange-token', data);
  }

  static openBackoffice(data: { token: string }) {
    const axiosInstance = this.axiosInstance();
    return axiosInstance.post('/backoffice/exchange-token', data);
  }
}
