import { combineReducers } from 'redux';
import authReduces from './auth';
import companyReducers from './company';

const rootReducer = combineReducers({
  auth: authReduces,
  company: companyReducers
});

export default rootReducer;
