// @flow
import * as React from 'react';

import { FormattedMessage } from 'react-intl';
import messages from 'Translations/messages';

type Props = {
  time: number,
  onDone: () => void | any
};
type State = {
  remainTime: number
};

class CountDown extends React.Component<Props, State> {
  timer = null;

  constructor(props: Props) {
    super(props);
    const { time } = props;
    this.state = { remainTime: time };
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      this.setState(prevState => ({
        remainTime: prevState.remainTime === 0 ? 0 : prevState.remainTime - 1
      }));
    }, 1000);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { remainTime } = prevState;
    if (remainTime === 0 && this.timer) {
      clearInterval(this.timer);
      this.props.onDone();
    }
  }

  formatTime = (time: number) => {
    let seconds = time % 60;
    let minutes = Math.floor(time / 60);
    minutes = minutes.toString().length === 1 ? `0${minutes}` : minutes;
    seconds = seconds.toString().length === 1 ? `0${seconds}` : seconds;
    return `${minutes}:${seconds}`;
  };

  render() {
    const { remainTime } = this.state;
    return (
      <div style={{ marginTop: '5px' }}>
        <FormattedMessage {...messages.masterLogin.pleaseWaitIn} />{' '}
        <span className="font-bold">{this.formatTime(remainTime)}</span>{' '}
        <FormattedMessage {...messages.masterLogin.forNextLogin} />
      </div>
    );
  }
}

export default CountDown;
