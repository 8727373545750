// @flow
import * as React from 'react';
import jwtDecode from 'jwt-decode';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { withCookies, Cookies } from 'react-cookie';

import { IntlProvider } from 'react-intl';
import { DEFAULT_LOCALE, messages as messagesI18N } from 'Translations/i18n';

import type { ContextRouter } from 'react-router-dom';
import type { Account } from 'Utils/types/Account.type';

import AuthBox from 'Components/AuthBox';
import Storage from 'Services/Storage';
import { AuthApi } from 'Services/Api';
import isEmail from 'Utils/isEmail';
import {
  LOCALE,
  MASTER_TOKEN,
  ACCESS_TOKEN,
  BACK_OFFICE_URL,
  COOKIE_OPTIONS
} from 'Utils/constants';
import PosBillLogo from '../../assets/img/posbill-logo.png';

const LocalStorage = new Storage();

type Props = {
  ...ContextRouter,
  cookies: typeof Cookies
};
type State = {
  error: string
};
class TokenExchange extends React.Component<Props, State> {
  state = {
    error: null
  };

  componentDidMount = async () => {
    const { location } = this.props;
    const queryParams = queryString.parse(location.search);
    if (queryParams && queryParams.token) {
      await this.exchangeToken(queryParams.token);
    }
  };

  exchangeToken = async token => {
    try {
      const response = await AuthApi.tokenExchange({ token });
      const { status, data } = response.data;
      if (status.isSuccess()) {
        const { username, masterToken, accessToken, lang } = data;

        const { cookies } = this.props;
        cookies.set(MASTER_TOKEN, masterToken, COOKIE_OPTIONS);
        cookies.set(ACCESS_TOKEN, accessToken, COOKIE_OPTIONS);
        cookies.set(LOCALE, lang, COOKIE_OPTIONS);

        const isMasterAccount = isEmail(username);
        if (!isMasterAccount) {
          const userData: Account = jwtDecode(accessToken);
          userData.accessToken = accessToken;
          LocalStorage.setAccountToLoggedList(userData);
        }

        // eslint-disable-next-line
        location.replace(BACK_OFFICE_URL);
      } else {
        this.setState({ error: 'There was an error while login!' });
      }
    } catch (e) {
      this.setState({ error: 'There was an error while login!' });
    }
  };

  render() {
    const { error } = this.state;
    return (
      <IntlProvider
        locale={DEFAULT_LOCALE}
        messages={messagesI18N[DEFAULT_LOCALE]}
      >
        <AuthBox>
          {!error ? (
            <i className="fa fa-spinner fa-spin" />
          ) : (
            <>
              <img
                src={PosBillLogo}
                style={{ width: '200px' }}
                alt="posbill-log"
                className="m-b-sm"
              />
              <div className="h3 text-warning">
                There was an error while login!
              </div>
              <br />
              <Link to="/" className="btn btn-primary btn-block">
                Go back
              </Link>
            </>
          )}
        </AuthBox>
      </IntlProvider>
    );
  }
}

export default withCookies(TokenExchange);
