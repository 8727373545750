// @flow
import * as React from 'react';
import classNames from 'classnames';
import { ErrorMessage, FormikActions } from 'formik';

type Props = {
  type: string,
  name: string,
  placeholder: string,
  error: string,
  touched: boolean,
  value: string,
  handleChange: FormikActions,
  handleBlur: FormikActions,
  ref?: { current: null | HTMLInputElement }
};

const Input = (props: Props) => {
  const {
    type,
    name,
    placeholder,
    error,
    touched,
    value,
    handleChange,
    handleBlur,
    ref
  } = props;
  return (
    <div
      className={classNames('form-group', {
        'has-error': error && touched
      })}
    >
      <input
        type={type}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        className="form-control"
        placeholder={placeholder}
        ref={ref}
      />
      <ErrorMessage
        name={name}
        render={msg => <span className="help-block text-left">{msg}</span>}
      />
    </div>
  );
};

Input.defaultProps = {
  ref: { current: null }
};

export default Input;
