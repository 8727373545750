// @flow
import * as React from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Formik, FormikActions, Form } from 'formik';

import { FormattedMessage } from 'react-intl';
import messages from 'Translations/messages';

import { AuthApi } from 'Services/Api';
import type { ApiResponse } from 'Services/Api/ApiResponse';
import {
  APP_FORGOT_PASSWORD_URL,
  APP_ONBOARDING_DOMAIN
} from 'Utils/constants';

import AuthBox from 'Components/AuthBox';
import Input from 'Components/Form/Input';
import Button from 'Components/Button';
import Alert from 'Components/Alert';
import CountDown from 'Components/CountDown';

import PosBillLogo from '../../../assets/img/posbill-logo.png';

type UserCertificate = {
  email: string,
  password: string,
  deviceToken: string
};

const ValidateShape = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Email is invalid')
    .required('Email is required'),
  password: Yup.string().required('Password is required')
});

type Props = {
  deviceToken: string,
  onLoginSuccess: (opts: any) => void
};
type State = {
  error: string,
  showCountDown: boolean,
  remainTime: number,
  disabledSubmit: boolean,
  masterEmail: string
};
class LoginBox extends React.Component<Props, State> {
  state = {
    error: '',
    showCountDown: false,
    remainTime: 0,
    disabledSubmit: false,
    passwordType: 'password'
  };

  onSubmit = async (
    { email, password }: UserCertificate,
    { setSubmitting }: FormikActions<UserCertificate>
  ) => {
    try {
      const { deviceToken, onLoginSuccess } = this.props;

      const requestBody = {
        email: email.trim(),
        password: password.trim(),
        deviceToken
      };
      const response = await AuthApi.masterLogin(requestBody);
      const { status, message, data }: ApiResponse = response.data;

      if (status.isSuccess()) {
        onLoginSuccess({ companies: data, masterPassword: password });
      } else {
        this.setState({ error: message });
        setSubmitting(false);

        if (status.isInvalidRequest() || status.isEmailBocked()) {
          const { remainTime } = data;
          this.onShowCountDown(remainTime);
        }
      }
    } catch (e) {
      toast.error(e.message);
      setSubmitting(false);
    }
  };

  onShowCountDown = remainTime => {
    this.setState({
      remainTime,
      showCountDown: true,
      disabledSubmit: true
    });
  };

  onCountDownDone = () => {
    this.setState({
      error: '',
      showCountDown: false,
      disabledSubmit: false
    });
  };

  togglePasswordVisibility = () => {
    const { passwordType } = this.state;

    this.setState({
      passwordType: passwordType === 'text' ? 'password' : 'text'
    });
  };

  render() {
    const {
      error,
      showCountDown,
      remainTime,
      disabledSubmit,
      passwordType
    } = this.state;

    const toggleIcon = passwordType === 'password' ? 'fa-eye' : 'fa-eye-slash';

    return (
      <AuthBox>
        <img
          src={PosBillLogo}
          style={{ width: '200px' }}
          alt="posbill-log"
          className="m-b-sm"
        />
        <h2 className="font-bold">
          <FormattedMessage {...messages.masterLogin.masterAccountLogin} />
        </h2>
        <p>
          <FormattedMessage {...messages.masterLogin.toSeeItInAction} />
        </p>

        {error && (
          <Alert type="danger">
            {error}
            {showCountDown && (
              <CountDown time={remainTime} onDone={this.onCountDownDone} />
            )}
          </Alert>
        )}

        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={ValidateShape}
          onSubmit={this.onSubmit}
        >
          {({
            errors,
            touched,
            values,
            handleChange,
            handleBlur,
            isValid,
            submitCount,
            isSubmitting
          }) => (
            <Form>
              <FormattedMessage {...messages.user.email}>
                {label => (
                  <Input
                    type="text"
                    name="email"
                    placeholder={label}
                    value={values.email}
                    error={errors.email}
                    touched={touched.email}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                )}
              </FormattedMessage>
              <FormattedMessage {...messages.user.password}>
                {label => (
                  <div className="password-container">
                    <Input
                      className="form-control"
                      type={passwordType}
                      name="password"
                      value={values.password}
                      error={errors.password}
                      touched={touched.password}
                      placeholder={label}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                    <i
                      id="eye"
                      className={`fa ${toggleIcon}`}
                      onClick={this.togglePasswordVisibility}
                    />
                  </div>
                )}
              </FormattedMessage>

              <Button
                submit
                fullWidth
                label={<FormattedMessage {...messages.common.login} />}
                type="primary"
                loading={isSubmitting}
                disabled={
                  (submitCount && !isValid) || isSubmitting || disabledSubmit
                }
              />
              <div className="forgot-password-link">
                <a
                  href={APP_FORGOT_PASSWORD_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage {...messages.common.forgotPassword} />
                </a>
              </div>
              <div>
                <FormattedMessage {...messages.common.dontHaveAccount} />{' '}
                <a
                  href={APP_ONBOARDING_DOMAIN}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage {...messages.common.register} />
                </a>
              </div>
              <div className="footer-links">
                <small>
                  <span>
                    <a
                      href={`${APP_ONBOARDING_DOMAIN}generalConditions`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessage {...messages.common.termsOfUse} />
                    </a>
                  </span>
                  <span>
                    <a
                      href={`${APP_ONBOARDING_DOMAIN}imprint`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessage {...messages.common.imprint} />
                    </a>
                  </span>
                  <span>
                    <a
                      href={`${APP_ONBOARDING_DOMAIN}privacyPolicy`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessage {...messages.common.privacy} />
                    </a>
                  </span>
                </small>
              </div>
            </Form>
          )}
        </Formik>
      </AuthBox>
    );
  }
}

export default LoginBox;
