// @flow
import * as React from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Formik, Form, FormikActions } from 'formik';

import { FormattedMessage } from 'react-intl';
import messages from 'Translations/messages';

import Input from 'Components/Form/Input';
import Button from 'Components/Button/Button';
import Alert from 'Components/Alert';
import AuthBox from 'Components/AuthBox';
import A from 'Components/A';

import { AuthApi } from 'Services/Api';

type VerificationCode = {
  code: string
};

const ValidationSchema = Yup.object().shape({
  code: Yup.string()
    .required('Verification code is required')
    .length(4, 'Verification code is exact 4 characters')
});

type Props = {
  deviceToken: string,
  masterEmail: string,
  onVerifySuccess: (opts: any) => void
};
type State = {
  error: string,
  resendingCode: boolean
};
class VerificationBox extends React.Component<Props, State> {
  state = {
    error: '',
    resendingCode: false
  };

  onSubmit = async (
    values: VerificationCode,
    { setSubmitting }: FormikActions
  ) => {
    try {
      const { deviceToken, masterEmail, onVerifySuccess } = this.props;

      const requestBody = { ...values, deviceToken, email: masterEmail };
      const response = await AuthApi.verification(requestBody);
      const { status, message, data } = response.data;

      if (status.isSuccess()) {
        const { masterToken } = data;
        onVerifySuccess({ masterToken });
      } else {
        this.setState({ error: message });
        setSubmitting(false);
      }
    } catch (error) {
      toast.error(error.message);
      setSubmitting(false);
    }
  };

  handleResendCode = async () => {
    try {
      this.setState({ resendingCode: true });
      const { deviceToken } = this.props;
      const response = await AuthApi.resendVerificationCode({ deviceToken });
      const { status, message } = response.data;
      if (status.isSuccess()) {
        toast.success(
          'Resend verification code successfully, please check your email',
          { position: toast.POSITION.TOP_RIGHT }
        );
      } else {
        toast.error(message, { position: toast.POSITION.TOP_RIGHT });
      }

      this.setState({ resendingCode: false });
    } catch (error) {
      this.setState({ resendingCode: false });
      toast.error(error.message);
    }
  };

  render() {
    const { error, resendingCode } = this.state;
    return (
      <AuthBox>
        <h2>
          <FormattedMessage {...messages.verification.header} />
        </h2>
        <h3 className="font-bold">
          <FormattedMessage {...messages.verification.firstTime} />
        </h3>
        <p>
          <FormattedMessage {...messages.verification.tips} />
        </p>

        {error && <Alert type="danger">{error}</Alert>}

        <Formik
          initialValues={{ code: '' }}
          validationSchema={ValidationSchema}
          onSubmit={this.onSubmit}
        >
          {({
            errors,
            values,
            touched,
            handleChange,
            handleBlur,
            submitCount,
            isValid,
            isSubmitting
          }) => (
            <Form>
              <FormattedMessage {...messages.verification.code}>
                {label => (
                  <Input
                    type="text"
                    name="code"
                    placeholder={label}
                    value={values.code}
                    error={errors.code}
                    touched={touched.code}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                )}
              </FormattedMessage>
              <FormattedMessage {...messages.common.login}>
                {label => (
                  <Button
                    submit
                    fullWidth
                    label={label}
                    type="primary"
                    loading={isSubmitting}
                    disabled={(submitCount && !isValid) || isSubmitting}
                  />
                )}
              </FormattedMessage>
              <div className="text-center">
                <A disable={resendingCode} onClick={this.handleResendCode}>
                  <FormattedMessage {...messages.verification.resendCode} />
                </A>
              </div>
            </Form>
          )}
        </Formik>
      </AuthBox>
    );
  }
}

export default VerificationBox;
