// @flow
import * as React from 'react';
import { sortBy } from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withCookies, Cookies } from 'react-cookie';
import type { ContextRouter } from 'react-router-dom';

import AuthBox from 'Components/AuthBox';
import AccountBox from 'Components/AccountBox';
import type { Account } from 'Utils/types/Account.type';
import { ACCESS_TOKEN, COOKIE_OPTIONS } from 'Utils/constants';
import { setAccessTokenAction, removeMasterToken } from 'Store/auth';
import Storage from 'Services/Storage';

const LocalStorage = new Storage();

type Props = {
  ...ContextRouter,
  setAccessToken: (accessToken: string) => void,
  removeMasterToken: Function,
  cookies: typeof Cookies
};
type State = {
  accounts: Account[]
};

class ListUser extends React.Component<Props, State> {
  state = {
    accounts: []
  };

  componentDidMount() {
    const accountsLoggedBefore = LocalStorage.getAccountsLogged();
    if (!accountsLoggedBefore.length) {
      this.props.history.push('/signin');
    } else {
      const accountsSortByUsername = sortBy(accountsLoggedBefore, 'username');
      this.setState({
        accounts: accountsSortByUsername
      });
    }
  }

  handleClick = (account: Account) => {
    if (account.accessToken) {
      const { accessToken } = account;

      const { cookies } = this.props;
      cookies.set(ACCESS_TOKEN, accessToken, COOKIE_OPTIONS);

      this.props.setAccessToken(accessToken);
      this.props.history.push('/');
    } else {
      this.props.history.push('/signin', account);
    }
  };

  render() {
    const { accounts } = this.state;

    return (
      <div>
        <AuthBox>
          <h2>Welcome</h2>
          <h4>Please choose an account</h4>

          <hr />

          {accounts.map(account => (
            <AccountBox
              key={account.uid}
              account={account}
              onClick={() => this.handleClick(account)}
            />
          ))}

          <hr />
          <div className="text-center">
            <div>Your name is not on the list?</div>
            <Link to="/signin">Use another account</Link>
          </div>
        </AuthBox>
        <div className="text-center">
          <button
            type="button"
            onClick={() => this.props.removeMasterToken()}
            style={{ background: '0', color: '#337ab7', border: 0 }}
          >
            Logout of Company session
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchTopProps = {
  removeMasterToken,
  setAccessToken: setAccessTokenAction
};

const ListUserWithCookie = withCookies(ListUser);

export default connect(
  null,
  mapDispatchTopProps
)(ListUserWithCookie);
