// @flow
import * as React from 'react';
import styled from 'styled-components';
import type { Company } from 'Utils/types/Company.type';

const Wrap = styled.div`
  width: 100%;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eee;
  font-size: 1.5rem;
  border-radius: 3px;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    background: #53b095;
    color: #fff;
  }
`;

type Props = {
  company: Company,
  onClick: () => void
};
const CompanyBox = ({ company, onClick }: Props) => {
  const { name } = company;
  return <Wrap onClick={onClick}>{name}</Wrap>;
};

export default CompanyBox;
