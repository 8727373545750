// @flow
import * as React from 'react';
import classNames from 'classnames';

type Props = {
  type: string,
  className?: string,
  children: React.Node
};

const Alert = ({ children, type, className }: Props) => {
  const classes = classNames('alert', `alert-${type}`, className);
  return <div className={classes}>{children}</div>;
};

Alert.defaultProps = {
  className: 'text-left'
};

export default Alert;
