// @flow
// eslint-disable-next-line
export class ResponseCode {
  code: number;
  constructor(code: number) {
    this.code = code;
  }

  isSuccess = () => this.code === 0;
  isUserTokenInvalid = () => this.code === 40303;
  isInvalidRequest = () => this.code === 40304;
  isEmailBocked = () => this.code === 40305;
  isCannotDelete = () => this.code === 40308;
  isNotFound = () => this.code === 40309;
  isInvalidFile = () => this.code === 40310;
  isInvoiceNotFound = () => this.code === 40311;
  isUserNotFound = () => this.code === 40312;
}

export type ApiResponse = {
  status?: ResponseCode,
  code?: number,
  message?: string,
  data?: any
};
