// @flow
import * as React from 'react';

import { FormattedMessage } from 'react-intl';
import messages from 'Translations/messages';

import AuthBox from 'Components/AuthBox';
import type { Company } from 'Utils/types';

import StoresBox from './StoresBox';

type Props = {
  companies: Company[],
  onChooseCompanySuccess: (opts: any) => void,
  onGoBack: () => void
};

class CompaniesBox extends React.PureComponent<Props> {
  render() {
    const { companies, onChooseCompanySuccess } = this.props;

    return (
      <AuthBox>
        <h2>
          <FormattedMessage {...messages.common.welcome} />
        </h2>
        <h4>
          <FormattedMessage {...messages.companies.pleaseChooseCompany} />
        </h4>

        <hr />

        {companies &&
          companies.length > 0 && (
            <StoresBox
              companies={companies}
              onChooseCompanySuccess={onChooseCompanySuccess}
              onGoBack={() => {}}
            />
          )}
      </AuthBox>
    );
  }
}

export default CompaniesBox;
