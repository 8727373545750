// @flow
import * as React from 'react';
import styled, { css } from 'styled-components';

const TextAsLink = styled.span`
  cursor: pointer;
  color: #337ab7;
  ${props =>
    props.disable &&
    css`
      color: #ddd;
      cursor: no-drop !important;
    `};
`;

type Props = {
  disable: boolean,
  onClick: () => any,
  children: React.Node
};

const handleClick = (disable, onClick) => {
  if (disable) {
    return;
  }
  onClick();
};

const A = (props: Props) => {
  const { disable, onClick, children } = props;
  return (
    <TextAsLink disable={disable} onClick={() => handleClick(disable, onClick)}>
      {disable ? <i className="fa fa-spin fa-spinner" /> : children}
    </TextAsLink>
  );
};

export default A;
