export const getFullUrl = (url, protocol) => {
  // eslint-disable-next-line
  const apiProtocol = protocol || location.protocol || 'http:';
  return `${apiProtocol}//${url}/`;
};
export const getFullKey = key => {
  const keyPrefix = process.env.REACT_APP_COOKIE_PREFIX || 'local_';
  return `${keyPrefix}${key}`;
};

export const APP_API_DOMAIN = getFullUrl(
  process.env.REACT_APP_API_DOMAIN,
  'https:'
);
export const APP_API_VERSION = process.env.REACT_APP_API_VERSION;
export const APP_FORGOT_PASSWORD_URL = getFullUrl(
  process.env.REACT_APP_FORGOT_PASSWORD_URL
);
export const APP_ONBOARDING_DOMAIN = getFullUrl(
  process.env.REACT_APP_ONBOARDING_DOMAIN
);
export const FRONT_OFFICE_URL = getFullUrl(
  process.env.REACT_APP_FRONTOFFICE_DOMAIN
);
export const BACK_OFFICE_URL = getFullUrl(
  process.env.REACT_APP_BACKOFFICE_DOMAIN
);
export const COOKIE_OPTIONS = {
  domain: process.env.REACT_APP_COOKIE_DOMAIN,
  path: '/'
};

export const DEVICE_TOKEN = getFullKey('device_token');
export const MASTER_TOKEN = getFullKey('master_token');
export const ACCESS_TOKEN = getFullKey('access_token');
export const ACCOUNTS_LOGGED = getFullKey('accounts_logged');
export const LOCALE = getFullKey('locale');
