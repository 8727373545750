// @flow
import * as React from 'react';
import styled from 'styled-components';

import { FormattedMessage } from 'react-intl';
import messages from 'Translations/messages';

import AuthBox from 'Components/AuthBox';
import CompanyBox from 'Components/CompanyBox';
import Button from 'Components/Button/Button';
import type { Company } from 'Utils/types/Company.type';

const Wrap = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-items: stretch;
  justify-content: center;
  gap: 1rem;
`;

type Props = {
  companies: Company[],
  onChooseCompanySuccess: (opts: any) => void,
  onGoBack: () => void
};
type State = {
  filteredCompanies: Company[]
};
class CompaniesBox extends React.Component<Props, State> {
  state = {
    filteredCompanies: this.props.companies
  };

  onSelectCompany = async (company: Company) => {
    const { onChooseCompanySuccess } = this.props;
    onChooseCompanySuccess(company);
  };

  onSearch = e => {
    const { value } = e.target;
    const { companies } = this.props;
    const filteredCompanies = [];

    companies.map((company: Company) => {
      const companyName = company.name.toLowerCase();
      if (companyName.indexOf(value.toLowerCase()) >= 0) {
        filteredCompanies.push(company);
      }

      return 1;
    });

    this.setState({ filteredCompanies });
  };

  render() {
    return (
      <AuthBox>
        <h2>
          <FormattedMessage {...messages.common.welcome} />
        </h2>
        <h4>
          <FormattedMessage {...messages.companies.pleaseChooseCompany} />
        </h4>

        <hr />

        <FormattedMessage {...messages.common.search}>
          {label => (
            <input
              type="text"
              className="form-control"
              placeholder={`${label}...`}
              onChange={this.onSearch}
            />
          )}
        </FormattedMessage>

        <br />

        <div
          style={{ maxHeight: '40vh', overflowY: 'auto', overflowX: 'hidden' }}
        >
          <Wrap>
            {this.state.filteredCompanies.map((company: Company) => (
              <CompanyBox
                key={company.id}
                company={company}
                onClick={() => this.onSelectCompany(company)}
              />
            ))}
          </Wrap>
          {this.state.filteredCompanies.length === 0 && (
            <div className=" m-t-md m-b-md">
              <FormattedMessage {...messages.companies.noCompanyFound} />
            </div>
          )}
        </div>

        <hr />

        <Button
          submit
          fullWidth
          onClick={() => this.props.onGoBack()}
          label={<FormattedMessage {...messages.common.back} />}
          type="primary"
        />
      </AuthBox>
    );
  }
}

export default CompaniesBox;
