// @flow
import * as React from 'react';
import { ToastContainer } from 'react-toastify';

type Props = {
  children: React.Node
};

const AuthBox = ({ children }: Props) => (
  <div className="ibox authentication-box">
    <div className="ibox-content middle-box text-center">
      {children}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </div>
  </div>
);

export default AuthBox;
