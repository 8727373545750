// @flow
import { AuthApi } from 'Services/Api';
import type { ApiResponse } from 'Services/Api/ApiResponse';

/* Fetch Information */
export const FETCHING = 'fetching_password_login_status';
export const FETCH_SUCCESS = 'fetch_password_login_status_success';
export const FETCH_FAILURE = 'fetch_password_login_status_failed';

const fetching = () => ({
  type: FETCHING
});
const fetchSuccess = (data: any) => ({
  type: FETCH_SUCCESS,
  payload: data
});
const fetchFailure = (message, status, data) => ({
  type: FETCH_FAILURE,
  payload: { message, status, data }
});

export const getPasswordLoginStatus = (masterToken: string) => (
  dispatch: any
) => {
  dispatch(fetching());
  AuthApi.passwordLoginStatus(masterToken)
    .then(response => {
      const { status, message, data }: ApiResponse = response.data;
      if (status.isSuccess()) {
        dispatch(fetchSuccess(data));
      } else {
        dispatch(fetchFailure(message, status, data));
      }
    })
    .catch(error => {
      dispatch(fetchFailure(error.message));
    });
};

const initialState = {
  isFetching: false,
  data: {},
  fetchError: {}
};

export type State = {
  +isFetching: boolean,
  +data: {},
  +fetchError: {}
};

type Action = {
  type: string,
  payload: any
};

const passwordLoginStatusReducers = (
  state: State = initialState,
  action: Action
) => {
  switch (action.type) {
    case FETCHING:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        fetchError: {}
      };
    case FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        fetchError: action.payload
      };
    default:
      return state;
  }
};

export default passwordLoginStatusReducers;
