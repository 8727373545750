// @flow
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import type { ContextRouter } from 'react-router-dom';

type Props = {
  ...ContextRouter
};

class UserTutorials extends React.Component<Props> {
  componentDidMount = () => {
    if (window.userpilot) {
      window.userpilot.reload();
    }
  };

  componentDidUpdate = prevProps => {
    const {
      location: { pathname }
    } = this.props;
    const previousLocation = prevProps.location.pathname;
    if (pathname !== previousLocation && window.userpilot) {
      window.userpilot.reload();
    }
    this.identifyUser();
  };

  identifyUser = () => {
    window.userpilot.anonymous();
  };

  render() {
    return this.props.children;
  }
}

export default withRouter(UserTutorials);
