// @flow
import * as React from 'react';
import * as Sentry from '@sentry/browser';

type Props = {
  children: React.Node
};
type State = {
  error: boolean
};

class ErrorBoundary extends React.Component<Props, State> {
  state = {
    error: false
  };

  componentDidCatch(error: any, errorInfo: any) {
    try {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN
      });
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
      this.setState({ error: true });
    } catch (err) {
      this.setState({ error: true });

      const msg = 'The error has occurred when init Sentry Service';
      // eslint-disable-next-line
      console.log(msg);
    }
  }

  render() {
    const env = process.env.NODE_ENV;
    const { error } = this.state;

    return error && env === 'production' ? (
      <div className="container">
        <div className="alert alert-danger">
          Somethings went wrong. Please contact the administrator!
        </div>
      </div>
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
