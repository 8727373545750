import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import HttpsRedirect from 'react-https-redirect';

import App from './App';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.scss';

import configureStore from 'Store/configureStore';
const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <HttpsRedirect>
        <App />
      </HttpsRedirect>
    </CookiesProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

if (
  module.hot &&
  process.env.NODE_ENV !== 'production' &&
  process.env.REACT_APP_HMR === 'true'
) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    ReactDOM.render(
      <Provider store={store}>
        <CookiesProvider>
          <NextApp />
        </CookiesProvider>
      </Provider>,
      document.getElementById('root')
    );
  });
}
