// @flow
import * as React from 'react';
import { withCookies, Cookies } from 'react-cookie';

// import { setMasterTokenAction } from 'Store/auth';

import { IntlProvider } from 'react-intl';
import { DEFAULT_LOCALE, messages as messagesI18N } from 'Translations/i18n';

import LoginBox from 'Containers/MasterLogin/LoginBox';
import CompaniesBox from 'Containers/MasterLogin/CompaniesBox';
import VerificationBox from 'Containers/MasterLogin/VerificationBox';

import generateDeviceToken from 'Utils/generateDeviceToken';
import {
  ACCESS_TOKEN,
  BACK_OFFICE_URL,
  COOKIE_OPTIONS,
  LOCALE,
  MASTER_TOKEN
} from 'Utils/constants';
import Storage from 'Services/Storage';
import { AuthApi } from 'Services/Api';
import { CompanyConverter } from 'Converters';

const LocalStorage = new Storage();

type Props = {
  cookies: typeof Cookies
};
type State = {
  deviceToken: string,
  masterEmail: string,
  masterPassword: string,
  companies: any[],
  showVerificationBox: boolean,
  showListCompanyBox: boolean
};
class MasterLogin extends React.Component<Props, State> {
  state = {
    deviceToken: '',
    masterEmail: '',
    masterPassword: '',
    companies: null,
    showVerificationBox: false,
    showListCompanyBox: false
  };

  componentDidMount() {
    let deviceToken = LocalStorage.getDeviceToken();
    if (!deviceToken) {
      deviceToken = generateDeviceToken();
      LocalStorage.setDeviceToken(deviceToken);
    }
    this.setState({ deviceToken });
  }

  onLoginSuccess = results => {
    const { companies, masterEmail, masterPassword } = results;
    if (companies && companies.length === 1) {
      const [company] = companies;
      this.setState({ masterPassword });
      this.showNormalLogin(company);
    } else if (companies && companies.length > 1) {
      this.setState({
        showListCompanyBox: true,
        companies: CompanyConverter.convertCompanies(companies),
        masterPassword
      });
    } else {
      this.setState({ showVerificationBox: true, masterEmail });
    }
  };

  handleAutoNormalLogin = (masterToken, password) => {
    const { cookies } = this.props;
    AuthApi.passwordLogin({ masterToken, password }).then(response => {
      const { status, data } = response.data;
      if (status.isSuccess()) {
        const { accessToken, lang } = data;
        cookies.set(ACCESS_TOKEN, accessToken, COOKIE_OPTIONS);
        cookies.set(LOCALE, lang, COOKIE_OPTIONS);
        // eslint-disable-next-line
        location.replace(BACK_OFFICE_URL);
      }
    });
  };

  showNormalLogin = company => {
    const { masterToken, accessToken, lang } = company;
    const { cookies } = this.props;
    const { masterPassword } = this.state;
    cookies.set(MASTER_TOKEN, masterToken, COOKIE_OPTIONS);

    if (accessToken) {
      cookies.set(ACCESS_TOKEN, accessToken, COOKIE_OPTIONS);
      cookies.set(LOCALE, lang, COOKIE_OPTIONS);
      // eslint-disable-next-line
      location.replace(BACK_OFFICE_URL);
    } else {
      this.handleAutoNormalLogin(masterToken, masterPassword);
      // TODO: Reseller Password
      // LocalStorage.setMasterToken(masterToken);
      // this.props.setMasterToken(masterToken);
    }
  };

  showLoginBox = () => {
    this.setState({
      showListCompanyBox: false,
      showVerificationBox: false
    });
  };

  renderVerificationBox = () => {
    const { deviceToken, masterEmail } = this.state;
    return (
      <VerificationBox
        deviceToken={deviceToken}
        masterEmail={masterEmail}
        onVerifySuccess={({ masterToken }) => this.showNormalLogin(masterToken)}
      />
    );
  };

  renderCompaniesBox = () => {
    const { companies } = this.state;
    return (
      <CompaniesBox
        companies={companies}
        onChooseCompanySuccess={company => this.showNormalLogin(company)}
        onGoBack={this.showLoginBox}
      />
    );
  };

  renderLoginBox = () => {
    const { deviceToken } = this.state;
    return (
      <LoginBox
        deviceToken={deviceToken}
        onLoginSuccess={this.onLoginSuccess}
      />
    );
  };

  renderAuthBox = () => {
    const { showVerificationBox, showListCompanyBox } = this.state;
    if (showVerificationBox) {
      return this.renderVerificationBox();
    }
    if (showListCompanyBox) {
      return this.renderCompaniesBox();
    }
    return this.renderLoginBox();
  };

  render() {
    return (
      <IntlProvider
        locale={DEFAULT_LOCALE}
        messages={messagesI18N[DEFAULT_LOCALE]}
      >
        {this.renderAuthBox()}
      </IntlProvider>
    );
  }
}

const MasterLoginWithCookie = withCookies(MasterLogin);

// const mapDispatchToProps = dispatch => ({
//   setMasterToken: masterToken => dispatch(setMasterTokenAction(masterToken))
// });

export default MasterLoginWithCookie;
