// @flow
import * as React from 'react';
import styled, { css } from 'styled-components';
import type { Account } from 'Utils/types/Account.type';

type Props = {
  account: Account,
  small?: boolean,
  onClick?: () => void
};

const Wrap = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 30px;
  &:hover {
    cursor: pointer;
    background: #eee;
  }
  ${({ small }) =>
    small &&
    css`
      padding: 5px 20px 5px 7px;
      background: #eee;
      margin: 10px;
      border-radius: 20px;
      border: 1px solid #00b6915c;
      &:hover {
        background: #ddd;
      }
    `};
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  ${({ small }) =>
    small &&
    css`
      width: 30px;
      height: 30px;
    `};
`;
const AvatarIcon = styled.i`
  font-size: 40px !important;
  color: #666;
  ${({ small }) =>
    small &&
    css`
      font-size: 25px !important;
    `};
`;

const WrapText = styled.div`
  padding-left: 15px;
`;
const TextName = styled.div`
  text-align: left;
  color: #555;
  ${({ small }) =>
    small &&
    css`
      line-height: 1;
    `};
`;
const AccountStatus = styled.div`
  text-align: left;
  font-size: 12px;
  font-style: italic;
`;
const Icon = styled.i`
  color: #555;
  margin-left: 10px;
  font-weight: bold !important;
`;

const AccountBox = ({ account, small, onClick }: Props) => {
  const { username, avatar } = account;
  return (
    <Wrap small={small} onClick={onClick}>
      {avatar ? (
        <Avatar small={small} src={avatar} alt={`${username} avatar`} />
      ) : (
        <AvatarIcon small={small} className="fa fa-user-circle-o" />
      )}

      <WrapText>
        <TextName small={small}>{username}</TextName>
        {!account.accessToken &&
          !small && <AccountStatus>logged out</AccountStatus>}
      </WrapText>
      {small && <Icon className="fa fa-angle-down" />}
    </Wrap>
  );
};

AccountBox.defaultProps = {
  small: false,
  onClick: () => {}
};

export default AccountBox;
