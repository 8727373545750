// @flow
import * as React from 'react';
import type { FormikActions } from 'formik';
import styled from 'styled-components';

type Props = {
  label: string,
  name: string,
  value: string,
  handleChange: FormikActions
};

const Label = styled.label`
  font-weight: 400;
`;
const Input = styled.input`
  margin: 0 !important;
  margin-right: 10px !important;
`;

const Checkbox = (props: Props) => {
  const { label, name, value, handleChange } = props;
  return (
    <div className="form-group">
      <Label htmlFor={name}>
        <Input
          type="checkbox"
          id={name}
          name={name}
          value={value}
          onChange={handleChange}
          checked={value}
        />
        <span>{label}</span>
      </Label>
    </div>
  );
};

export default Checkbox;
