// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { ACCESS_TOKEN, BACK_OFFICE_URL, MASTER_TOKEN } from 'Utils/constants';

import { setMasterTokenAction, setAccessTokenAction } from 'Store/auth';

import ErrorBoundary from 'Components/ErrorBoundary';
import UserTutorials from 'Components/UserTutorials';

import MasterLogin from 'Containers/MasterLogin';
import TokenExchange from 'Containers/TokenExchange';
import SwitchStores from 'Containers/SwitchStores';
import ListUser from 'Containers/ListUser';
import UserLogin from 'Containers/UserLogin';

type Props = {
  accessToken: string,
  masterToken: string,
  setMasterToken: (masterToken: string) => void,
  setAccessToken: (accessToken: string) => void,
  cookies: typeof Cookies
};

class App extends React.Component<Props> {
  componentDidMount() {
    const { cookies } = this.props;

    const masterToken = cookies.get(MASTER_TOKEN);
    if (masterToken) {
      this.props.setMasterToken(masterToken);
    }

    const accessToken = cookies.get(ACCESS_TOKEN);
    if (accessToken) {
      this.props.setAccessToken(accessToken);
    }
  }

  renderMasterLoginPage = () => (
    <Switch>
      <Route path="/exchange" component={TokenExchange} />
      <Route path="/" component={MasterLogin} />
    </Switch>
  );

  renderCompanyLoginPage = () => (
    <Switch>
      <Route path="/stores" component={SwitchStores} />
      <Route path="/exchange" component={TokenExchange} />
      <Route path="/signin" component={UserLogin} />
      <Route path="/" component={ListUser} />
    </Switch>
  );

  renderLoggedPage = () => (
    <Switch>
      <Route path="/exchange" component={TokenExchange} />
      <Route
        path="/"
        component={() => {
          // eslint-disable-next-line
          location.replace(BACK_OFFICE_URL);
          return null;
        }}
      />
    </Switch>
  );

  renderRoutes = () => {
    const { accessToken, masterToken } = this.props;
    if (!accessToken) {
      if (masterToken) {
        return this.renderCompanyLoginPage();
      }
      return this.renderMasterLoginPage();
    }
    return this.renderLoggedPage();
  };

  render() {
    return (
      <ErrorBoundary>
        <Router>
          <UserTutorials>{this.renderRoutes()}</UserTutorials>
        </Router>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = state => ({
  accessToken: state.auth.accessToken,
  masterToken: state.auth.masterToken
});
const mapDispatchToProps = {
  setMasterToken: setMasterTokenAction,
  setAccessToken: setAccessTokenAction
};

const AppWithCookie = withCookies(App);

export default connect(mapStateToProps, mapDispatchToProps)(AppWithCookie);
