// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withCookies, Cookies } from 'react-cookie';
import type { ContextRouter } from 'react-router-dom';

import { setMasterTokenAction } from 'Store/auth';

import { IntlProvider } from 'react-intl';
import { DEFAULT_LOCALE, messages as messagesI18N } from 'Translations/i18n';

import { CompanyConverter } from 'Converters';
import {
  ACCESS_TOKEN,
  BACK_OFFICE_URL,
  COOKIE_OPTIONS,
  LOCALE,
  MASTER_TOKEN
} from 'Utils/constants';
import { AuthApi } from 'Services/Api';
import type { ApiResponse } from 'Services/Api/ApiResponse';

import CompaniesBox from './CompaniesBox';

type Props = {
  ...ContextRouter,
  cookies: typeof Cookies
};
type State = {
  companies: any[]
};
class SwitchStores extends React.Component<Props, State> {
  state = {
    companies: []
  };

  componentDidMount = async () => {
    await this.getStores();
  };

  getStores = async () => {
    const { cookies } = this.props;
    const masterToken = cookies.get(MASTER_TOKEN);

    try {
      const response = await AuthApi.getCompanies({ masterToken });
      const { status, message, data }: ApiResponse = response.data;
      if (status.isSuccess()) {
        this.setState({ companies: CompanyConverter.convertCompanies(data) });
      } else {
        toast.error(message);
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  showNormalLogin = async company => {
    const { cookies } = this.props;
    const { masterToken, companyCode } = company;
    try {
      const payload = { companyCode, masterToken };
      const response = await AuthApi.openBackoffice(payload);
      const { status, data } = response.data;

      if (status.isSuccess()) {
        const { accessToken, lang } = data;
        cookies.set(ACCESS_TOKEN, accessToken, COOKIE_OPTIONS);
        cookies.set(LOCALE, lang, COOKIE_OPTIONS);
        // eslint-disable-next-line
        location.replace(BACK_OFFICE_URL);
      } else {
        const { accessToken, lang } = company;
        cookies.set(MASTER_TOKEN, masterToken, COOKIE_OPTIONS);

        if (accessToken) {
          cookies.set(ACCESS_TOKEN, accessToken, COOKIE_OPTIONS);
          cookies.set(LOCALE, lang, COOKIE_OPTIONS);
          // eslint-disable-next-line
          location.replace(BACK_OFFICE_URL);
        } else {
          this.props.setMasterToken(masterToken);
          this.props.history.push('/signin');
        }
      }
    } catch (e) {
      toast.error('There was an error while login!');
    }
  };

  renderCompaniesBox = () => {
    const { companies } = this.state;
    return (
      <CompaniesBox
        companies={companies}
        onChooseCompanySuccess={company => this.showNormalLogin(company)}
        onGoBack={() => {}}
      />
    );
  };

  render() {
    return (
      <IntlProvider
        locale={DEFAULT_LOCALE}
        messages={messagesI18N[DEFAULT_LOCALE]}
      >
        {this.renderCompaniesBox()}
      </IntlProvider>
    );
  }
}

const SwitchStoresWithCookie = withCookies(SwitchStores);

const mapDispatchToProps = dispatch => ({
  setMasterToken: masterToken => dispatch(setMasterTokenAction(masterToken))
});

export default connect(
  null,
  mapDispatchToProps
)(withCookies(SwitchStoresWithCookie));
